import { useStyletron, styled } from 'baseui'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ourColors } from './Colors'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import { Theme } from 'baseui/theme'
import { StyleObject } from 'styletron-react'
import { ButtonProps, Button } from 'baseui/button'

import { withOverrides } from './overrides'

const overrides: StyleObject = {
  paddingTop: '23px',
  paddingBottom: '13px',
  paddingLeft: 0,
  paddingRight: 0,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  height: '100%',
  fontSize: '14px',
  lineHeight: 'normal',
  fontFamily: 'Roboto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  overflowWrap: 'normal',
  textAlign: 'center',
  textDecorationLine: 'none',
  backgroundColor: ourColors.white,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: ourColors.interfaceGrey,
  borderLeftStyle: 'solid',
  borderLeftWidth: '1px',
  borderLeftColor: ourColors.interfaceGrey,
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderRightColor: ourColors.interfaceGrey,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: ourColors.interfaceGrey,
  ':hover': {
    backgroundColor: ourColors.lightGrey
  }
}

const IconButtonBase = withOverrides(Button, {
  BaseButton: { style: overrides }
})
interface IconButtonLinkProps {
  icon: IconDefinition
  text: string
  url: string
}
interface IconButtonProps extends ButtonProps {
  icon: IconDefinition
  text: React.ReactNode
}

const IconButtonLinkWrapper = styled<{}, React.ComponentClass<GatsbyLinkProps<{}>, {}>, Theme>(
  GatsbyLink,
  () => {
    return overrides
  }
)

export const IconButton = ({ icon, text, ...props }: IconButtonProps) => {
  const [css] = useStyletron()

  return (
    <IconButtonBase {...props}>
      <FontAwesomeIcon
        icon={icon}
        className={css({
          fontSize: '26px',
          marginBottom: '13px',
          color: ourColors.copyGrey,
          alignSelf: 'center'
        })}
      />
      <div
        className={css({
          fontWeight: 'normal',
          color: ourColors.copyGrey,
          paddingLeft: '22px',
          paddingRight: '22px'
        })}
      >
        {text}
      </div>
    </IconButtonBase>
  )
}

export const IconButtonLink = ({ icon, text, url }: IconButtonLinkProps) => {
  const [css] = useStyletron()

  return (
    <IconButtonLinkWrapper key={text} to={url}>
      <FontAwesomeIcon
        icon={icon}
        className={css({
          fontSize: '26px',
          marginBottom: '13px',
          color: ourColors.copyGrey,
          alignSelf: 'center'
        })}
      />
      <div
        className={css({
          fontWeight: 'normal',
          color: ourColors.copyGrey,
          paddingLeft: '22px',
          paddingRight: '22px'
        })}
      >
        {text}
      </div>
    </IconButtonLinkWrapper>
  )
}
