import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import {
  getUnionLogo,
  ImagesResponse,
  MostRecentNewsResponse,
  MostRecentNewsResponseItem,
  getCurrentNews
} from '../api/cms'
import constants from '../constants'
import { Heading } from '../components/Heading'
import { HeadingLevel } from 'baseui/heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { useStyletron } from 'baseui'
import { HomeNav } from '../components/HomeNav'
import { navigate } from 'gatsby'
import { ListCard } from '../components/ListCard'

export const UnionLogo = () => {
  const [css] = useStyletron()

  const { token, union } = React.useContext(UserContext)

  const [unionLogo, setUnionLogo] = React.useState('')

  React.useEffect(() => {
    if (token && union) {
      getUnionLogo(token, union).then((response: ImagesResponse) => {
        if (response.meta.total_count > 0) {
          const prefix = process.env.NODE_ENV !== 'production' ? constants.cmsUrl : ''
          setUnionLogo(prefix + response.items[0].meta.download_url)
        }
      })
    }
  }, [token, union])

  return (
    <>
      {unionLogo ? (
        <img src={unionLogo} className={css({ marginTop: '29px' })} data-testid="unionLogo" />
      ) : null}
    </>
  )
}

const LoggedInContent = () => {
  const { preferredName, role } = React.useContext(UserContext)

  return (
    <>
      <Grid gridGutters={13}>
        <Cell span={[2.25, 4]}>
          <HeadingLevel>
            <Heading data-testid="loginInfo">Welcome back {preferredName}</Heading>
          </HeadingLevel>
        </Cell>
        <Cell
          span={[1.5, 2]}
          skip={[0.25, 0]}
          overrides={{
            Cell: {
              style: { textAlign: 'right' }
            }
          }}
        >
          <UnionLogo />
        </Cell>
      </Grid>
      <HomeNav role={role} />
      <Grid>
        <Cell span={[4, 6]}>
          <HomeNews />
        </Cell>
      </Grid>
    </>
  )
}

export const HomeNews = () => {
  const { token } = React.useContext(UserContext)
  const [newsCards, setNewsCards] = React.useState<React.ReactNode[] | null>(null)
  const [css] = useStyletron()
  const downloadUrl = (content: MostRecentNewsResponseItem) => {
    if (!content.news_image) {
      return ''
    }
    if (process.env.NODE_ENV === 'production') {
      return content.news_image.meta.download_url
    } else {
      return constants.cmsUrl + content.news_image.meta.download_url
    }
  }

  React.useEffect(() => {
    if (token)
      getCurrentNews(token).then((response: MostRecentNewsResponse) => {
        if (response.meta.total_count > 0) {
          setNewsCards(
            response.items.map(content => {
              return (
                <ListCard
                  key={'news' + content.id}
                  url={'/news?id=' + content.id}
                  imageUrl={downloadUrl(content)}
                  title={content.title}
                  bodyContent={content.subtitle}
                  category={content.hazard_category ? content.hazard_category.title : 'Other'}
                />
              )
            })
          )
        }
      })
  }, [token])
  return (
    <>
      {newsCards && (
        <div className={css({ marginTop: '32px' })} data-testid="newsLink">
          {newsCards}
        </div>
      )}
    </>
  )
}

const IndexPage = () => {
  const { token } = React.useContext(UserContext)
  React.useEffect(() => {
    if (!token) {
      navigate('/landing-page')
    }
  }, [token])
  return token ? <LoggedInContent /> : null
}

const LayoutIndexPage = () => (
  <Layout searchBarOpen={true} backButton={true}>
    <IndexPage />
  </Layout>
)

export default LayoutIndexPage
