import React from 'react'
import { faPen, faClipboardList, faTools } from '@fortawesome/free-solid-svg-icons'
import { Grid, Cell } from 'baseui/layout-grid'
import { IconButtonLink } from './IconButton'

interface HomeNavProps {
  role: 'HSR' | 'Organiser' | null
}

export const HomeNav = ({ role }: HomeNavProps) => {
  const buttonContent = [
    { icon: faPen, text: 'Log new issue', url: '/issues/create-issue' },
    { icon: faClipboardList, text: 'Logged issues', url: '/issues/issue-list' },
    { icon: faTools, text: (role || 'Your') + ' tools', url: '/tools-home' }
  ]
  return (
    <nav>
      <Grid
        overrides={{
          Grid: {
            style: { alignItems: 'stretch' }
          }
        }}
      >
        {buttonContent.map(value => {
          return (
            <Cell key={value.text} span={[1.33, 2]}>
              <IconButtonLink text={value.text} icon={value.icon} url={value.url} />
            </Cell>
          )
        })}
      </Grid>
    </nav>
  )
}
